// Конвертация данных в CSV-формат
export const convertToCSV = (data) => {
  if (!data || !Array.isArray(data) || data.length === 0) return "";

  const keys = Object.keys(data[0]); // Получаем заголовки CSV (ключи первого объекта)
  const csvRows = [];

  // Добавляем заголовки
  csvRows.push(keys.join(","));

  // Добавляем строки с данными
  data.forEach((row) => {
    const values = keys.map((key) => (row[key] !== undefined ? `"${row[key]}"` : ""));
    csvRows.push(values.join(","));
  });

  return csvRows.join("\n");
};

// Создание ссылки для скачивания CSV
export const createCSVDownloadLink = (csvContent, fileName = "data.csv") => {
  const blob = new Blob([csvContent], { type: "text/csv" });
  return URL.createObjectURL(blob);
};
