import React from "react";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";

function TablesHistorical() {
  const email = localStorage.getItem("userEmail");
  let iframeSrc = "";
  if (
    [
      "Artemyusi@gmail.com",
      "Vlad0986613794@gmail.com",
      "xxxuko@gmail.com",
      "Hembarskyi@gmail.com",
      "andrew@salesbrush.com",
    ].includes(email)
  ) {
    iframeSrc =
      "https://lookerstudio.google.com/embed/reporting/3000c573-6076-486b-bf2f-08455895b7fd/page/DbPlD";
  } else if (email === "andreylipovskiy0@gmail.com") {
    iframeSrc =
      "https://lookerstudio.google.com/embed/reporting/3000c573-6076-486b-bf2f-08455895b7fd/page/p_x95127y2id";
  } else if (email === "yarikbars99@gmail.com") {
    iframeSrc =
      "https://lookerstudio.google.com/embed/reporting/3000c573-6076-486b-bf2f-08455895b7fd/page/p_tn4k67y2id";
  } else if (email === "mcerneva2@gmail.com") {
    iframeSrc =
      "https://lookerstudio.google.com/embed/reporting/3000c573-6076-486b-bf2f-08455895b7fd/page/p_p03s5j0tkd";
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mt={6}
        mb={3}
        sx={{
          width: "calc(100vw - 280px)",
          height: "150vw",
          overflow: "hidden",
          marginRight: "20px",
        }}
      >
        <iframe
          width="95%"
          height="100%"
          src={iframeSrc}
          style={{ border: 0, overflow: "hidden" }}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default TablesHistorical;
