import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "CUSTOM_COMPONENTS/custom_dropdown";
import OptionsCheckboxList from "CUSTOM_COMPONENTS/options_checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CustomNumericInputField from "CUSTOM_COMPONENTS/custom_numeric_input_field";
import CustomInputFieldMultiline from "CUSTOM_COMPONENTS/custom_input_field_multiline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MDInput from "../../../components/MDInput";
import Button from "@mui/material/Button";
import axios from "axios";
import { renderTips } from "CUSTOM_COMPONENTS/render_TIPS";
import DialogFormGenerateDaDomain from "../../GENERATE_LINK/DA_generate_domain_dialog";
import { fetchCategories } from "../../GENERATE_LINK/constants";
import { Tips_for_all } from "../constants";
import CustomDropdownInput from "../../../CUSTOM_COMPONENTS/custom_dropdown_input";
import { convertToCSV, createCSVDownloadLink } from "./convert_to_csv";

function ScaleCampaign() {
  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";
  const API_URL_2 = "https://dashboard-1049948018753.us-central1.run.app";
  const API_URL_UPDATE = "https://fb-campaign-creator-only-campaigns-tmgx45bhsa-uc.a.run.app";
  const API_URL_GET_DATA = "https://fb-campaign-creator-get-data-1049948018753.us-central1.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [CampaignNamesAll, setCampaignNamesAll] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("PAUSED");
  const statusesAll = ["ACTIVE", "PAUSED"];

  const [selectedBidOptions, setSelectedBidOptions] = useState(["+0%"]);
  const [selectedRate, setSelectedRate] = useState("CUSTOM");
  const [inputRate, setInputRate] = useState("1");
  const [ratesAll, setRatesAll] = useState(["CUSTOM"]);

  const platformOptions = ["FB + INSTAGRAM", "FB", "INSTAGRAM", "ALL"];
  const [selectedPlatformOptions, setSelectedPlatformOptions] = useState([]);

  const osOptions = ["IOS + ANDROID", "IOS", "ANDROID", "ALL"];
  const [selectedOsOptions, setSelectedOsOptions] = useState([]);

  const [selectedType, setSelectedType] = useState("");
  const typesAll = ["ABO | COST_CAP", "ABO | BID_CAP", "ABO Highest Delivery"];

  const [selectedAdsetSetting, setSelectedAdsetSetting] = useState("");
  const adsetSettingsAll = [
    "Create All Ads in One Ad Set",
    "Copy Active Ad Sets with Active Ads from Parent Campaign",
    "1 Ad in 1 Adset",
  ];

  const [selectedPage, setSelectedPage] = useState("");
  const [pagesAll, setPagesAll] = useState([]);

  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);

  const [inputBudget, setInputBudget] = useState("5");
  const [inputLinkForFB, setInputLinkForFB] = useState("");

  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const defaultDate = tomorrow.toISOString().split("T")[0]; // 'YYYY-MM-DD' format

  const [date, setDate] = useState(defaultDate);
  const [time, setTime] = useState("00:00:00");

  const activateRulesOptions = ["YES", "NO"];
  const [activateRules, setActivateRules] = useState("YES");
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [inputSearchTerm, setInputSearchTerm] = useState("");
  const [inputCt, setInputCt] = useState("184");
  const [categoriesAll, setCategoriesAll] = useState([]);

  const [downloadUrl, setDownloadUrl] = useState("");

  const Tips = [
    "To create new campaigns, you must select a parent campaign from which all ad sets will be copied.",
    "You need to select the account and fb_page on which new campaigns will be created.",
    "Choose what type of campaign to create by selecting the desired one from the list.",
    "The campaign stage for created campaigns will be set to 'L1'.",
  ];
  const Tips2 = [
    "You can choose the status of the newly created ad sets (active or paused).",
    "Only ACTIVE Ad Sets and Ads can be copied.",
    "When selecting multiple options, ad sets will be created for each of these options.",
    "The maximum number of ad sets is 50.",
    "The maximum number of ads in one ad set is 50.",
  ];
  const Tips3 = [
    "DO NOT CHANGE the values in these fields if you are creating a campaign without bid/cost.",
    "When selecting multiple options, ad sets will be created for each of these options.",
  ];

  const Tips4 = [
    "Don't forget to enable the required rules after the campaign creation if you selected 'YES'.",
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL}/get_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);

        const pageResponse = await fetch(`${API_URL}/get_pages_all/`);
        const pagesData = await pageResponse.json();
        setPagesAll(pagesData);

        const categoriesResponse = await fetchCategories();
        setCategoriesAll(categoriesResponse);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCampaignName) {
      const campaign_id = selectedCampaignName.split(" | ")[0];

      // Отправка POST-запроса
      axios
        .post(`${API_URL_2}/api/get_campaigns_data/`, { campaign_id })
        .then((response) => {
          const dataDict = response.data;
          setRatesAll([
            "CUSTOM",
            `CPA current: ${dataDict["CPA current"]}`,
            `RPC current: ${dataDict["RPC current"]}`,
            `CPA_mean_7_days: ${dataDict["CPA_mean_7_days"]}`,
            `RPC_mean_7_days: ${dataDict["RPC_mean_7_days"]}`,
          ]);
        })
        .catch((error) => {
          console.error("Error fetching campaign data:", error);
        });
    }
  }, [selectedCampaignName]);

  useEffect(() => {
    const fetchCampaignDataG = async () => {
      if (!selectedCampaignName) return;

      // Making the API request
      try {
        const response = await fetch(`${API_URL_GET_DATA}/campaign_get_data/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_email: userEmail, // Assume userEmail is defined somewhere
            properties: {
              campaign_id: selectedCampaignName,
            },
          }),
        });

        const data = await response.json();

        // Assigning the returned values to state variables
        setSelectedType(
          selectedCampaignName.includes("BID")
            ? "ABO | BID_CAP"
            : selectedCampaignName.includes("COST")
            ? "ABO | COST_CAP"
            : "ABO Highest Delivery"
        );
        setSelectedAccount(data.account_id);
        setSelectedPage(data.page_id);
        setInputLinkForFB(data.fb_link_for_fb);
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };

    fetchCampaignDataG();
  }, [selectedCampaignName]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/get_campaigns_names_adsets_types/`);
        const data = await response.json();

        let filteredCampaignNames;
        filteredCampaignNames = [...new Set(data.map((item) => item.campaign_id_name))];

        // Update state with filtered values
        setCampaignNamesAll(filteredCampaignNames);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleScaleCampaign = () => {
    if (
      !selectedCampaignName ||
      !selectedAccount ||
      !selectedPage ||
      !selectedType ||
      !selectedAdsetSetting ||
      !selectedStatus ||
      !inputBudget ||
      !selectedRate ||
      !inputRate ||
      selectedPlatformOptions.length === 0 ||
      selectedOsOptions.length === 0 ||
      selectedBidOptions.length === 0
    ) {
      toast.error("Please fill all required fields");
      return;
    }
    if (selectedRate !== "CUSTOM") {
      const rateValue = selectedRate?.split(":")[1]?.trim();
      if (rateValue === "0" || rateValue === "null" || rateValue === null) {
        toast.error(`Please select a valid start rate not 0 or null (${selectedRate})`);
        return;
      }
    }
    // console.log(`
    //   Campaign Name Data: ${selectedCampaignName}
    //   Ad Account: ${selectedAccount}
    //   FB Page: ${selectedPage}
    //   Campaign Type: ${selectedType}
    //   Adset Count Option: ${selectedAdsetSetting}
    //   Adset Status: ${selectedStatus}
    //   Budget: ${inputBudget}
    //   Platform Options: ${selectedPlatformOptions}
    //   OS Options: ${selectedOsOptions}
    //   Bid Start Rate: ${selectedRate}
    //   Custom Rate Value: ${inputRate}
    //   Bid Options: ${selectedBidOptions}
    //   FB Link for FB: ${inputLinkForFB}
    // `);

    setIsLoading(true);
    fetch(`${API_URL_UPDATE}/campaign_scale/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          campaign_name_data: selectedCampaignName,
          ad_account: selectedAccount,
          fb_page: selectedPage,
          campaign_type: selectedType,
          adset_count_option: selectedAdsetSetting,
          adset_status: selectedStatus,
          budget: inputBudget,
          platform_options: selectedPlatformOptions,
          os_options: selectedOsOptions,
          bid_start_rate: selectedRate,
          custom_rate_value: inputRate,
          // custom_percentage: inputPercentage,
          bid_options: selectedBidOptions,
          fb__link_for_fb: inputLinkForFB.replace('"', ""),
          activate_rules: activateRules,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.data || `HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data.data, null, 2));
        const csvContent = convertToCSV(data.data_to_csv); // Конвертируем данные в CSV
        if (csvContent) {
          const downloadUrl = createCSVDownloadLink(csvContent);
          setDownloadUrl(downloadUrl);
        }
        toast.success("Campaign successfully created!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCheckCampaign = () => {
    if (
      !selectedCampaignName ||
      !selectedAccount ||
      !selectedPage ||
      !selectedType ||
      !selectedAdsetSetting ||
      !selectedStatus ||
      !inputBudget ||
      !selectedRate ||
      !inputRate ||
      selectedPlatformOptions.length === 0 ||
      selectedOsOptions.length === 0 ||
      selectedBidOptions.length === 0
    ) {
      toast.error("Please fill all required fields");
      return;
    }
    if (selectedRate !== "CUSTOM") {
      const rateValue = selectedRate?.split(":")[1]?.trim();
      if (rateValue === "0" || rateValue === "null" || rateValue === null) {
        toast.error(`Please select a valid start rate not 0 or null (${selectedRate})`);
        return;
      }
    }

    setIsLoading(true);
    fetch(`${API_URL_UPDATE}/campaign_check/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          schedule_date: date,
          schedule_time: time,
          campaign_name_data: selectedCampaignName,
          ad_account: selectedAccount,
          fb_page: selectedPage,
          campaign_type: selectedType,
          adset_count_option: selectedAdsetSetting,
          adset_status: selectedStatus,
          budget: inputBudget,
          platform_options: selectedPlatformOptions,
          os_options: selectedOsOptions,
          bid_start_rate: selectedRate,
          custom_rate_value: inputRate,
          bid_options: selectedBidOptions,
          fb__link_for_fb: inputLinkForFB,
          activate_rules: activateRules,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully Checked!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleCheckCampaign}>
            Check 👀
          </button>
          <button className="button-list-button" onClick={handleScaleCampaign}>
            Scale campaign💨
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div
        className="response-message-container"
        style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}
      >
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <>
            {/* Карточка для отображения JSON */}
            <Card
              className="response-message"
              sx={{
                overflow: "auto",
                marginBottom: "16px",
                width: "100%", // Занимает 80% ширины контейнера
                maxWidth: "1000px", // Ограничение максимальной ширины
              }}
            >
              <pre>{responseMessage}</pre>
            </Card>

            {/* Кнопка для скачивания CSV */}
            {downloadUrl && (
              <Button
                variant="contained"
                color="success"
                href={downloadUrl}
                download="properties.csv"
                style={{ marginTop: "16px", marginBottom: "16px" }} // Отступ сверху
              >
                Download CSV
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  const handleOptionsPlatformChange = (options) => {
    setSelectedPlatformOptions(options);
  };

  const handleOptionsOsChange = (options) => {
    setSelectedOsOptions(options);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const BidPercentageSelector = () => {
    const [bidOptions, setBidOptions] = useState(["+0%"]);

    const handleInputChange = (event, value) => {
      // Проверяем, является ли введенное значение числом
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        setBidOptions([`+${parsedValue}%`, `-${parsedValue}%`]);
      }
    };

    return (
      <Grid item xs={12} md={12} mt={2}>
        <Autocomplete
          multiple
          defaultValue={selectedBidOptions}
          options={bidOptions}
          onInputChange={handleInputChange}
          onChange={(event, newValue) => setSelectedBidOptions(newValue)}
          renderInput={(params) => <TextField {...params} label="" variant="standard" />}
        />
      </Grid>
    );
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3}>
                <MDBox p={2} mb={3} display="flex" alignItems="center">
                  <img
                    src="https://www.svgrepo.com/show/263359/tree.svg"
                    alt="Image"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <MDTypography variant="h5">
                    &nbsp;&nbsp;Parent & Child campaigns Setup
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  mt={3}
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderTips(Tips)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomDropdownInput
                          selectedValue={selectedCampaignName}
                          setSelectedValue={setSelectedCampaignName}
                          options={CampaignNamesAll}
                          label={"Campaign ID | Name"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <CustomDropdown
                          selectedCategory={selectedAccount}
                          setSelectedCategory={setSelectedAccount}
                          categories={accountsAll}
                          category_name={"FB - AD account"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4.5}>
                        <CustomDropdown
                          selectedCategory={selectedPage}
                          setSelectedCategory={setSelectedPage}
                          categories={pagesAll}
                          category_name={"FB - page"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={2.5}>
                        <CustomDropdown
                          selectedCategory={selectedType}
                          setSelectedCategory={setSelectedType}
                          categories={typesAll}
                          category_name={"Select campaign type"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <MDTypography variant="h6" color="text">
                          Fb__link_for_fb
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Button
                          variant="contained"
                          color="white"
                          onClick={handleOpenEditDialog}
                          fullWidth
                        >
                          Generate DA domain and FB link
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={inputLinkForFB}
                          setInputValue={setInputLinkForFB}
                          label={"Ignore this field if you want to use the parent fb_link value."}
                          multiline
                        />
                      </Grid>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexWrap="wrap"
                        mt={5}
                        ml={3}
                      >
                        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                          {renderTips(Tips4)}
                        </MDBox>
                      </MDBox>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={activateRules}
                          setSelectedCategory={setActivateRules}
                          categories={activateRulesOptions}
                          category_name={"Make visible to rules ?"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/530147/physics.svg"
                            alt="Image"
                            style={{ width: "40px", height: "40px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;Schedule created campaign start (время в UTC — будет
                            автоматически приведено к времени вашего рекламного аккаунта)
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        flexWrap="wrap"
                        mt={5}
                        ml={3}
                      >
                        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                          {renderTips(Tips_for_all)}
                        </MDBox>
                      </MDBox>
                      <Grid item xs={12} md={12} container alignItems="center">
                        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 5 }}>
                          <MDInput type="date" value={date} onChange={handleDateChange} />
                          <MDInput type="time" value={time} onChange={handleTimeChange} />
                          <Button
                            onClick={() => {
                              setDate("");
                              setTime("");
                            }}
                            variant="contained"
                            color="white"
                          >
                            🚫 SKIP
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              <MDBox p={2} mb={3}>
                <Grid item xs={12} md={12}>
                  <MDBox mt={1} display="flex" alignItems="center">
                    <img
                      src="https://www.svgrepo.com/show/530147/physics.svg"
                      alt="Image"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <MDTypography variant="h6">&nbsp;&nbsp;Ad Set Configuration</MDTypography>
                  </MDBox>
                </Grid>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  mt={3}
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderTips(Tips2)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <CustomDropdown
                          selectedCategory={selectedAdsetSetting}
                          setSelectedCategory={setSelectedAdsetSetting}
                          categories={adsetSettingsAll}
                          category_name={"Ad Set option"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomDropdown
                          selectedCategory={selectedStatus}
                          setSelectedCategory={setSelectedStatus}
                          categories={statusesAll}
                          category_name={"Ad Set status after creation"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomNumericInputField
                          inputValue={inputBudget}
                          setInputValue={setInputBudget}
                          label={"Daily adset budget (in $)"}
                          allowFloat={true}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDBox mt={1} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/312450/leaf-fluttering-in-wind.svg"
                            alt="Image"
                            style={{ width: "40px", height: "40px" }}
                          />
                          <MDTypography variant="h6" color="text">
                            &nbsp;&nbsp;Select Publisher Platforms (default: FB + INSTAGRAM)
                          </MDTypography>
                        </MDBox>
                        <OptionsCheckboxList
                          onOptionsChange={handleOptionsPlatformChange}
                          typesAll={platformOptions}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDBox mt={1} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/312450/leaf-fluttering-in-wind.svg"
                            alt="Image"
                            style={{ width: "40px", height: "40px" }}
                          />
                          <MDTypography variant="h6" color="text">
                            &nbsp;&nbsp;Select Os (default: IOS + ANDROID)
                          </MDTypography>
                        </MDBox>
                        <OptionsCheckboxList
                          onOptionsChange={handleOptionsOsChange}
                          typesAll={osOptions}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              {selectedType !== "ABO Highest Delivery" && (
                <>
                  <MDBox p={2} mb={3}>
                    <Grid item xs={12} md={12}>
                      <MDBox mt={3} display="flex" alignItems="center">
                        <img
                          src="https://www.svgrepo.com/show/530147/physics.svg"
                          alt="Image"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <MDTypography variant="h6">
                          &nbsp;&nbsp;Bid Settings (Skip this section for campaigns without bid or
                          cost controls)
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      flexWrap="wrap"
                      mt={3}
                    >
                      <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                        {renderTips(Tips3)}
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox p={2}>
                    <Grid container spacing={2} direction="column" alignItems="stretch">
                      <MDBox p={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={9}>
                            <CustomDropdown
                              selectedCategory={selectedRate}
                              setSelectedCategory={setSelectedRate}
                              categories={ratesAll}
                              category_name={"Calculate bid|cost amount from"}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            {selectedRate === "CUSTOM" && (
                              <CustomNumericInputField
                                inputValue={inputRate}
                                setInputValue={setInputRate}
                                label={"Custom rate value (in $)"}
                                allowFloat={true}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <MDBox mt={1} display="flex" alignItems="center">
                              <img
                                src="https://www.svgrepo.com/show/312450/leaf-fluttering-in-wind.svg"
                                alt="Image"
                                style={{ width: "40px", height: "40px" }}
                              />
                              <MDTypography variant="h6" color="text">
                                &nbsp;&nbsp;Bid Percentage Modifier
                              </MDTypography>
                            </MDBox>
                            <BidPercentageSelector />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </MDBox>
                </>
              )}
              <MDBox pt={2} px={2}>
                <ButtonList />
                <MDBox p={2} mb={3} display="flex" alignItems="center">
                  <img
                    src="https://www.svgrepo.com/show/404652/arrow-direction-right-pointer-next.svg"
                    alt="Red Arrow"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <MDTypography variant="h5">&nbsp;&nbsp;Check limits before scale</MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Edit Dialog */}
      <DialogFormGenerateDaDomain
        openEditDialog={openEditDialog}
        handleEditDialogClose={handleCloseEditDialog}
        inputSearchTerm={inputSearchTerm}
        setInputSearchTerm={setInputSearchTerm}
        API_URL={"https://handle-links-1049948018753.us-central1.run.app"}
        setInputRedirectUrl={setInputLinkForFB}
        setResponseMessage={setInputLinkForFB}
        inputCt={inputCt}
        setInputCt={setInputCt}
        categoriesAll={categoriesAll}
      />
    </DashboardLayout>
  );
}

export default ScaleCampaign;
